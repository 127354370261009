import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  canGoBack: boolean;
  renderUntil: number;
}

const initialState: UIState = {
  canGoBack: false,
  renderUntil: 10,
};

export default createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCanGoBack: (state, action: PayloadAction<boolean>) => {
      state.canGoBack = action.payload;
    },
    renderReset: (state) => {
      state.renderUntil = initialState.renderUntil;
    },
    renderMore: (state) => {
      state.renderUntil += initialState.renderUntil;
    },
  },
});
