import { createTheme, responsiveFontSizes, Theme, DeprecatedThemeOptions } from '@mui/material';

const fonts = {
  heading: "'Roboto Slab', 'serif'",
};

const base: DeprecatedThemeOptions = {
  typography: {
    h1: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '3rem',
    },
    h2: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '2.5rem',
    },
    h3: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '2rem',
    },
    h4: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '1.75rem',
    },
    h5: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '1.25rem',
    },
    body1: {
      margin: '1em 0',
    },
  },
};

export const light = responsiveFontSizes(
  createTheme({
    ...base,
    palette: {
      mode: 'light',
      primary: {
        main: '#424242',
        dark: '#1b1b1b',
        light: '#6d6d6d',
        contrastText: '#fff',
      },
      background: {
        default: '#fafafa',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          text: {
            fontWeight: 'bold',
            color: '#0000008a',
          },
        },
      },
    },
  }),
);

export const dark = responsiveFontSizes(
  createTheme({
    ...base,
    palette: {
      mode: 'dark',
      primary: {
        main: '#DEDDDE',
        dark: '#DEDDDE',
        light: '#777',
        contrastText: '#fff',
      },
      background: {
        default: '#18181a',
        paper: '#232327',
      },
      divider: '#3D3D3D',
    },
  }),
);

export const sideNavWidth = {
  md: 280,
  sm: 220,
};

export function getBorder(theme: Theme) {
  return theme.palette.mode === 'light'
    ? {
        boxShadow: theme.palette.mode === 'light' ? '#0003 0px 1px 2px 0px' : 'none',
      }
    : {
        border: theme.palette.mode === 'dark' ? `thin solid ${theme.palette.divider}` : 'none',
      };
}

export const padding = (theme: Theme) => (...values: number[]) => values.map((value) => theme.spacing(value)).join(' ');
