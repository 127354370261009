import StorageService from './storage-service';

export default class MetaStorageService extends StorageService<string> {
  constructor() {
    super('meta');
  }

  async setTimestamp(key: string, timestamp: Date): Promise<void> {
    await this.setItem(MetaStorageService.getTimestampKey(key), timestamp.toISOString());
  }

  async getTimestamp(key: string): Promise<Date | null> {
    let timestamp: Date | null = null;
    try {
      const timestamp = await this.getItem(MetaStorageService.getTimestampKey(key));
      return timestamp !== null ? new Date(timestamp) : null;
    } catch (error) {
      console.warn(`Could not read timestamp '${key}'.`);
    }

    return timestamp;
  }

  private static getTimestampKey(key: string) {
    return `ts_${key}`;
  }
}
