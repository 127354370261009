import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import { RootState } from '..';
import { client, flatten } from '../../services/contentful';
import { Recipe, Category, PlainEntry } from '../../types';
import { recipeIndex } from './search';

interface ErrorState {
  init: boolean;
  sync: boolean;
}

interface RecipeState {
  ready: boolean;
  errors: ErrorState;
  filter?: string;
  category?: string;
  recipes: Record<string, PlainEntry<Recipe>>;
  categories: Record<string, PlainEntry<Category>>;
}

const initialState: RecipeState = {
  ready: false,
  errors: {
    init: false,
    sync: false,
  },
  recipes: {},
  categories: {},
};

export const fetchRecipe = createAsyncThunk('recipes/fetchRecipe', async (slug: string, { getState }) => {
  const state = getState() as RootState;
  if (state.recipes.recipes[slug]) {
    return undefined;
  }

  const recipes = await client.getEntries<Recipe>({
    content_type: 'recipe',
    'fields.slug': slug,
  });

  if (!recipes.items.length) {
    throw new Error(`Recipe '${slug}' does not exist.`);
  }

  return recipes.items[0];
});

export default createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<string | undefined>) => {
      state.filter = action.payload;
    },
    setCategory: (state, action: PayloadAction<string | undefined>) => {
      state.category = action.payload;
    },
    addCategories: (state, action: PayloadAction<PlainEntry<Category>[]>) => {
      state.categories = { ...state.categories, ...keyBy(action.payload, (item) => item.fields.slug) };
    },
    addRecipes: (state, action: PayloadAction<PlainEntry<Recipe>[]>) => {
      state.recipes = { ...state.recipes, ...keyBy(action.payload, (item) => item.fields.slug) };

      action.payload.forEach((recipe) =>
        recipeIndex.addAsync(recipe.sys.id, {
          id: recipe.sys.id,
          title: recipe.fields.title,
          description: recipe.fields.description,
          ingredients: flatten(recipe.fields.ingredients),
        }),
      );
    },
    init: (state, action: PayloadAction<boolean>) => {
      state.ready = true;
      state.errors.init = action.payload;
    },
    setError: (state, action: PayloadAction<{ type: keyof ErrorState; value: boolean }>) => {
      state.errors[action.payload.type] = action.payload.value;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(fetchRecipe.fulfilled, (state, action) => {
      if (action.payload) {
        state.recipes[action.payload.fields.slug] = action.payload;
      }
    }),
});
