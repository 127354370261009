import { client } from 'services/contentful';
import { Category, ContentType, PlainEntry } from 'types';

import MetaStorageService from './meta-storage-service';
import StorageService from './storage-service';

const updateKey = 'category_update';

type T = PlainEntry<Category>;

export default class CategoryStorageService extends StorageService<T> {
  constructor(private readonly meta = new MetaStorageService()) {
    super('categories');
  }

  async sync(): Promise<T[]> {
    const now = new Date();
    const lastUpdate = await this.meta.getTimestamp(updateKey);
    const items = await this.fetchCategories(lastUpdate, now);

    try {
      await Promise.all(items.map((item) => this.setItem(item.fields.slug, item)));
      await this.meta.setTimestamp(updateKey, now);
    } catch (error) {
      if (error instanceof Error && error.name === 'QuotaExceededError') {
        console.warn('Failed to save categories: quota exceeded. Proceeding without storage.');
      } else {
        throw error;
      }
    }

    return items;
  }

  private async fetchCategories(start: Date | null, end: Date) {
    const { items } = await client.getEntries<Category>({
      content_type: ContentType.Category,
      ...(start ? { 'sys.updatedAt[gte]': start.toISOString() } : {}),
      'sys.updatedAt[lte]': end.toISOString(),
      limit: 1000,
    });

    return items;
  }
}
