import localForage from 'localforage';

export default abstract class StorageService<T> {
  private readonly table: LocalForage;

  protected constructor(tableName: string) {
    this.table = localForage.createInstance({ name: tableName });
  }

  async setItem(id: string, item: T): Promise<void> {
    await this.table.setItem(id, JSON.stringify(item));
  }

  async getItem(id: string): Promise<T | null> {
    const item = await this.table.getItem(id);
    return typeof item === 'string' ? JSON.parse(item) : null;
  }

  async getAll(): Promise<T[]> {
    const ids = await this.table.keys();
    const items = await Promise.all(ids.map((id) => this.getItem(id)));
    return items.filter((item): item is Awaited<T> => item !== null);
  }

  async reset(): Promise<void> {
    await this.table.dropInstance();
  }
}
