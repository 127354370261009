import { imgWidth } from 'config';
import { Category, PlainEntry, Recipe } from 'types';

import { Publisher } from './publisher';
import CategoryStorageService from './storage/category-storage-service';
import MetaStorageService from './storage/meta-storage-service';
import RecipeStorageService from './storage/recipe-storage-service';

export enum SyncEvent {
  Recipes = 'recipes',
  Categories = 'categories',
  Init = 'init',
  Sync = 'sync',
  Reset = 'reset',
}

type EventData =
  | {
      type: SyncEvent.Recipes;
      data: PlainEntry<Recipe>[];
    }
  | {
      type: SyncEvent.Categories;
      data: PlainEntry<Category>[];
    }
  | {
      type: SyncEvent.Init;
      error?: unknown;
    }
  | {
      type: SyncEvent.Sync;
      error?: unknown;
    }
  | { type: SyncEvent.Reset };

export default class SyncService extends Publisher<EventData> {
  private static instance: SyncService;

  private constructor(
    private readonly categoryStorage = new CategoryStorageService(),
    private readonly recipeStorage = new RecipeStorageService(),
    private readonly metaStorage = new MetaStorageService(),
  ) {
    super();
    this.publishCategories = this.publishCategories.bind(this);
    this.publishRecipes = this.publishRecipes.bind(this);
  }

  static get(): SyncService {
    SyncService.instance = SyncService.instance || new SyncService();
    return SyncService.instance;
  }

  async init(): Promise<void> {
    try {
      await Promise.all([
        this.categoryStorage.getAll().then(this.publishCategories),
        this.recipeStorage.getAll().then(this.publishRecipes),
      ]);

      this.publish({ type: SyncEvent.Init });
    } catch (error) {
      this.publish({ type: SyncEvent.Init, error });
    }
  }

  async sync(): Promise<void> {
    try {
      await Promise.all([
        this.categoryStorage.sync().then(this.publishCategories),
        this.recipeStorage.sync().then(this.publishRecipes),
      ]);

      if (navigator.serviceWorker?.controller) {
        const recipes = await this.recipeStorage.getAll();
        const urls = recipes
          .map((recipe) => recipe.fields.image?.fields.file.url)
          .filter((url) => url)
          .map((url) => `${url}?fm=webp&w=${imgWidth * 2}`);

        navigator.serviceWorker.controller.postMessage({
          type: 'PRECACHE_IMAGES',
          urls,
        });
      }

      this.publish({ type: SyncEvent.Sync });
    } catch (error) {
      this.publish({ type: SyncEvent.Sync, error });
    }
  }

  async reset(): Promise<void> {
    try {
      await Promise.all([this.categoryStorage.reset(), this.recipeStorage.reset(), this.metaStorage.reset()]);
    } catch (error) {
      console.error('Could not clear application storage.');
    }
  }

  async getStats() {
    const [categories, recipes] = await Promise.all([this.categoryStorage.getAll(), this.recipeStorage.getAll()]);
    return {
      categories: categories.length,
      recipes: recipes.length,
    };
  }

  private publishCategories(data: PlainEntry<Category>[]) {
    if (data.length) {
      this.publish({ type: SyncEvent.Categories, data });
    }
  }

  private publishRecipes(data: PlainEntry<Recipe>[]) {
    if (data.length) {
      this.publish({ type: SyncEvent.Recipes, data });
    }
  }
}
