import { Document } from 'flexsearch';

interface IndexedRecipe {
  id: string;
  description?: string;
  ingredients: string;
  title: string;
}

export const recipeIndex = new Document<IndexedRecipe>({
  tokenize: 'full',
  cache: 100,
  document: {
    id: 'id',
    index: ['description', 'ingredients', 'title'],
  },
});
