type Subscription = number;
type Callback<T> = (data: T) => unknown;

export abstract class Publisher<T> {
  constructor(
    private subscriberIndex: Subscription = 1,
    private readonly subscribers = new Map<Subscription, Callback<any>>(),
  ) {}

  subscribe(callback: (event: T) => unknown): () => void {
    const subscription = this.subscriberIndex++;
    this.subscribers.set(subscription, callback);
    return () => {
      this.subscribers.delete(subscription);
    };
  }

  protected publish(event: T): void {
    this.subscribers.forEach((callback) => callback(event));
  }
}
