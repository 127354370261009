import { Theme, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface Props {
  variant: 'page' | 'section';
}

const LazyFallback = ({ variant }: Props) => {
  const { classes, cx } = useStyles();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 250);
    return () => clearTimeout(timer);
  }, []);

  const typography = variant === 'page' ? 'h3' : 'body2';
  return (
    <Typography variant={typography} className={cx(classes.default, visible && classes.visible)}>
      Ladataan...
    </Typography>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  default: {
    textAlign: 'center',
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  visible: {
    opacity: 1,
  },
}));

export default LazyFallback;
