import { Button, Typography } from '@mui/material';
import React from 'react';
import SyncService from 'services/sync-service';
import { makeStyles } from 'tss-react/mui';

const ErrorPage = () => {
  const { classes } = useStyles();

  const handleRetry = async () => {
    await SyncService.get().reset();
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1">Reseptien lataus ei onnistunut.</Typography>
      <Button onClick={handleRetry}>Yritä uudelleen</Button>
    </div>
  );
};

const useStyles = makeStyles()({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ErrorPage;
