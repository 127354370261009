import { Asset, Entry, RichTextContent } from 'contentful';

export type PlainEntry<T> = Omit<Entry<T>, 'toPlainObject' | 'update'>;

export enum ContentType {
  Recipe = 'recipe',
  Category = 'category',
}

export interface Category {
  title: string;
  slug: string;
}

export interface Recipe {
  author?: string;
  categories?: Entry<Category>[];
  cookingTime?: number;
  description?: string;
  image?: Asset;
  ingredients?: RichTextContent;
  instructions?: RichTextContent;
  preparationTime?: number;
  servings?: number;
  slug: string;
  source?: string;
  sourceUrl?: string;
  title: string;
}
