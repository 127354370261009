import { client } from 'services/contentful';
import { ContentType, PlainEntry, Recipe } from 'types';

import MetaStorageService from './meta-storage-service';
import StorageService from './storage-service';

const updateKey = 'recipe_update';

type T = PlainEntry<Recipe>;

export default class RecipeStorageService extends StorageService<T> {
  constructor(private readonly meta = new MetaStorageService()) {
    super('recipes');
  }

  async sync(): Promise<T[]> {
    const now = new Date();
    const lastUpdate = await this.meta.getTimestamp(updateKey);
    const items = await this.fetchRecipes(lastUpdate, now);

    try {
      await Promise.all(items.map((item) => this.setItem(item.fields.slug, item)));
      await this.meta.setTimestamp(updateKey, now);
    } catch (error) {
      if (error instanceof Error && error.name === 'QuotaExceededError') {
        console.warn('Failed to save recipes: quota exceeded. Proceeding without storage.');
      } else {
        throw error;
      }
    }

    return items;
  }

  private async fetchRecipes(start: Date | null, end: Date) {
    const { items } = await client.getEntries<Recipe>({
      content_type: ContentType.Recipe,
      ...(start ? { 'sys.updatedAt[gte]': start.toISOString() } : {}),
      'sys.updatedAt[lte]': end.toISOString(),
      limit: 1000,
    });

    return items;
  }
}
