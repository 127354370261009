import '@fontsource/roboto-slab/latin-400.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';
import 'core-js/features/array';
import { createRoot } from 'react-dom/client';

import Root from './app/Root/Root';
import * as serviceWorker from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    console.log('Service worker has been updated. Enabling updated service worker immediately.');
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  },
});
