import { createSelector } from '@reduxjs/toolkit';
import { keyBy, uniq } from 'lodash';
import orderBy from 'lodash/orderBy';
import { PlainEntry, Recipe } from 'types';

import { RootState } from '..';
import { recipeIndex } from './search';

export const getRecipe = (slug: string) => (state: RootState) => state.recipes.recipes[slug];

export const getCategory = (state: RootState) => state.recipes.category;

export const getFilter = (state: RootState) => state.recipes.filter;

export const getRecipes = createSelector(
  (state: RootState) => state.recipes.recipes,
  (recipes) => orderBy(Object.values(recipes), 'sys.updatedAt', 'desc'),
);

export const getCategoryRecipes = createSelector(getRecipes, getCategory, (recipes, category) =>
  category ? recipes.filter((recipe) => recipe.fields.categories?.some((c) => c.fields.slug === category)) : recipes,
);

export const getCategoryRecipesById = createSelector(getCategoryRecipes, (recipes) =>
  keyBy(recipes, (recipe) => recipe.sys.id),
);

export const getFilteredRecipes = createSelector(
  getCategoryRecipes,
  getCategoryRecipesById,
  getFilter,
  (recipes, recipesById, filter) => {
    const search = filter?.toLowerCase();

    if (search) {
      const results = keyBy(recipeIndex.search(search), 'field');
      const ids = uniq([
        ...(results.title?.result ?? []),
        ...(results.description?.result ?? []),
        ...(results.ingredients?.result ?? []),
      ]);

      return ids.map((id) => recipesById[id]).filter((x): x is PlainEntry<Recipe> => !!x);
    }

    return recipes;
  },
);

export const getCategories = createSelector(
  (state: RootState) => state.recipes.categories,
  (categories) => Object.values(categories).sort((a, b) => a.fields.title.localeCompare(b.fields.title)),
);

export const hasRecipes = createSelector([getRecipes], (recipes) => !!recipes.length);
