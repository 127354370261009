import { createClient } from 'contentful';

const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_ACCESS_TOKEN } = process.env;

if (!REACT_APP_CONTENTFUL_SPACE_ID) {
  throw new Error('REACT_APP_CONTENTFUL_SPACE_ID env is required.');
}

if (!REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
  throw new Error('REACT_APP_CONTENTFUL_ACCESS_TOKEN env is required.');
}

export const client = createClient({
  space: REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export function flatten(data: any) {
  if (data?.value) {
    return data.value;
  }

  return data?.content?.map(flatten).join(', ');
}
