import React from 'react';

export const redirectTo404 = () => {
  window.location.href = '/404';
};

const NotFoundRedirect = () => {
  React.useEffect(redirectTo404, []);
  return null;
};

export default NotFoundRedirect;
