import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from 'store';

import { dark, light } from '../../theme';
import App from '../App/App';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const Root = () => {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
  return (
    <React.StrictMode>
      <CssBaseline />
      <Provider store={store}>
        <HelmetProvider>
          <Router>
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={darkMode ? dark : light}>
                <App />
              </ThemeProvider>
            </CacheProvider>
          </Router>
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
